import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
import createPersistedState from "vuex-persistedstate";
import { getNoticeList } from "@/api/login";

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
  state: {
    user: false,
    active: "/home/workbench",
    menus: [],
    //tags数组
    tags: [],
    catch_components: [],
    //tagsview标签显示隐藏
    isCollapse: false,
    clientHeight: document.documentElement.clientHeight || document.body.clientHeight,
    Num: 0,
    total: 0,
    status: null,
    notice_content: "",
    ws: void 0,
    noticeNum: null
  },
  mutations: {
    // 登录
    login(state, user) {
      state.user = user;
      sessionStorage.setItem("userInfo", user);
    },
    // 退出
    logout(state, user) {
      state.user = user;
      sessionStorage.setItem("userInfo", "");
      sessionStorage.setItem("logintoken", "");
      sessionStorage.setItem("userdata", "");
      sessionStorage.setItem("Refresh_Token", "");
      state.tags = [];
      state.active = "/home/workbench";
    },
    //改变state
    setActive(state, active) {
      state.active = active;
    },
    //保存menus
    setMenus(state, menus) {
      state.menus = menus;
    },
    //--------------------------------------------------------------------------------
    pushtags(state, val) {
      if (val.title === "分析页") return;
      //如果等于-1说明tabs不存在那么插入，否则什么都不做
      //findindex找角标，循环判断一下，如果等于那么就代表有相同的，就不必添加，如果找不到那就是-1.就添加
      let result = state.tags.findIndex(item => item.title === val.title);
      result === -1 ? state.tags.push(val) : "";
    },
    //关闭标签
    closeTab(state, val) {
      //同上，找角标，然后用角标的位置对应删除一位。splice：这是数组的删除方法
      let result = state.tags.findIndex(item => item.title === val.title);
      state.tags.splice(result, 1);
    },
    //关闭所有tagsview标签
    cleartagsview(state, val) {
      //清空数组
      state.tags = [];
      //跳转到首页,val接受传过来的当前路由
      if (val !== "/home/workbench") {
        router.push({ path: "/home/workbench" });
      }
    },
    clearothertags(state, val) {
      state.tags = [{ path: val.path, title: val.name }];
    },
    //改变tagsview显示隐藏
    changeisshow(state) {
      state.isCollapse = !state.isCollapse;
    },
    addKeepAliveCache(state, val) {
      if (val === "/home/workbench") {
        return;
      }
      if (state.catch_components.indexOf(val) === -1) {
        state.catch_components.push(val);
      }
    },
    // 删除keepalive缓存
    removeKeepAliveCache(state, val) {
      let cache = state.catch_components;
      for (let i = 0; i < cache.length; i++) {
        if (cache[i] === val) {
          cache.splice(i, 1);
        }
      }
      state.catch_components = cache;
    },
    //clientHeight高度
    listenHeight(state, val) {
      state.clientHeight = val;
    },
    //-----------------socket-------------------
    // 保存 ws
    setWs(state, payload) {
      // 保存在两个位置 ：
      //  本地（cookie）
      // setWs(payload);
      //  内存（vuex）
      state.ws = payload;
    },
    setNum(state, data) {
      state.Num = data;
    },
    setTotal(state, data) {
      state.total = data;
    },
    setStatus(state, data) {
      state.status = data;
    },
    setNoticeContent(state, data) {
      state.notice_content = data;
    },
    setNoticeData(state, data) {
      state.noticeNum = data;
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    addAsync({ commit }, param) {
      getNoticeList({ notice_type: param }).then();
      getNoticeList({ notice_type: "firstOrderPreparation" }).then();
    }
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
});
