// en.js
import enLocale from "element-ui/lib/locale/lang/en"; //引入element语言包
export default {
  main: {
    message: "message",
    display: "display",
    shelfNumber: "shelfNumber",
    shelfStatus: "shelfStatus",
    operate: "operate",
    addShelves: "add shelves",
    area: "area"
  },
  btn: {
    delete: "delete",
    add: "add",
    edit: "edit",
    export: "export",
    Print: "print",
    import: "import",
    batchDeletion: "batch deletion",
    batchExport: "batch export",
    batchPrinting: "batch printing",
    ExcelImport: "Excel import",
    NewWarehouseArea: "New warehouse area"
  },
  placeholder: {
    PleaseSelectTheWarehouse: "Please select the warehouse",
    PleaseEnterTheNewShelfNumber: "Please enter the new shelf number",
    EnterTheWarehouseName: "Enter the warehouse name",
    EnterWarehouseDetails: "Enter warehouse details"
  },
  ...enLocale
};
