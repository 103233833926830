<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { bindMembeId, unbindMembeId } from "@/api/login";
  import { getClientId, getToken, setClientId } from "@/utils/auth";
  import socketMixins from "@/socket/sockerMixins";
  import { mapActions, mapMutations } from "vuex";
  import store from "@/vuex/store";

  export default {
    name: "App",
    mixins: [socketMixins],
    data() {
      return {
        ws: void 0 // ws链接
      };
    },
    mounted() {
      // 创建客户端的WebSocket对象并保存client_id
      if (getToken()) {
        this.conn();
      }
      window.addEventListener(
        "hashchange",
        () => {
          var currentPath = window.location.hash.slice(1); // 获取输入的路由
          if (this.$router.path !== currentPath) {
            this.$router.push(currentPath); // 动态跳转
          }
        },
        false
      );
    },
    beforeDestroy() {
      // 销毁时关闭链接
      if (getToken()) {
        this.closeWebSocket();
      }
    },
    methods: {
      ...mapMutations(["setWs"]),
      ...mapActions(["addAsync"]),
      // //关闭WebSocket连接，关闭后重连，保持最少一个长连接存在，退出后关闭
      async closeWebSocket() {
        this.ws.close();
        this.conn();
      },
      async conn() {
        const that = this;
        // 解绑loginIndex中的长链接
        await unbindMembeId({ client_id: getClientId().client_id });
        let wsURL = process.env.NODE_ENV == "development" ? "ws://worker.e-kc.com:2348" : "wss://worker.e-kc.vip:2348";
        this.ws = new WebSocket(wsURL);
        this.setWs(this.ws);
        //连接成功建立的回调方法
        this.ws.onopen = function () {
          console.log("app链接成功");
        };
        //   接收信息
        this.ws.onmessage = async function (message) {
          if (JSON.parse(message.data).client_id) {
            setClientId({ client_id: JSON.parse(message.data).client_id });
            if (getToken()) {
              await bindMembeId({
                client_id: JSON.parse(message.data).client_id
              });
              await store.dispatch("addAsync", "firstOrderInform");
            }
          }
          //   不同的类型执行不同的操作
          switch (JSON.parse(message.data).notice_type) {
            case "cp_shop":
              that.showNotify(JSON.parse(message.data));
              break;
            case "news":
              that.showMessage(JSON.parse(message.data));
              break;
            case "system":
              that.showSystem(JSON.parse(message.data));
              break;
            case "order":
              that.submitData(JSON.parse(message.data));
              break;
            case "order_error":
              that.resultData(JSON.parse(message.data));
              break;
            case "order_enter":
              that.resultData(JSON.parse(message.data));
              break;
            case "up_cp_product":
              that.submitData(JSON.parse(message.data));
              break;
            case "cp_product_enter":
              that.resultData(JSON.parse(message.data));
              break;
            case "statistics":
              that.setNoticeData(JSON.parse(message.data));
              break;
          }
          // }
          // if (
          //     JSON.parse(message.data).status === 411 ||
          //     JSON.parse(message.data).status === 200
          // ) {
          //   // 判断type执行相对应的操作
          //   //that.searchData.shop_id 执行脚本后推送过来的数据
          //   // that.getSock(message)
          // }
        };

        //连接发生错误的回调方法
        this.ws.onerror = function () {
          console.log("WebSocket连接发生错误");
        };
        //发送信息
        this.ws.send = function () {
          console.log("像服务端发送信息");
        };
        //连接关闭的回调方法
        this.ws.onclose = function () {
          console.log("WebSocket连接关闭app");
        };
      }
    }
  };
</script>

<style>
  html,
  body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px;
    margin: 0 auto;
  }

  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
  }
</style>
