<template>
  <div>
    <el-popover placement="bottom" popper-class="customPopper" trigger="hover" width="350" @show="getFristData">
      <template #reference>
        <el-badge :value="total" class="item-info-pop">
          <i class="el-icon-bell" style="font-size: 20px"></i>
        </el-badge>
      </template>
      <div>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane :label="`通知(${noticeData && noticeData.length})`" name="first">
            <el-collapse
              v-if="noticeData && noticeData.length > 0"
              v-model="activeNames"
              style="height: 500px; overflow: auto"
              @change="handleChange"
            >
              <el-collapse-item v-for="(item, index) in noticeData" :key="index" :name="index + 1">
                <template slot="title">
                  <span style="font-size: 18px; font-weight: bold">{{ item.notice_title }}</span>
                </template>
                <div style="display: flex; justify-content: space-between">
                  <span>{{ item.notice_content }}</span>
                  <div style="width: 20px"></div>
                  <el-button style="margin-right: 15px" type="text" @click="isRead(item.notice_id, 'firstOrderInform')"
                    >已读
                  </el-button>
                </div>
              </el-collapse-item>
            </el-collapse>
            <el-empty v-else description="暂无数据"></el-empty>
          </el-tab-pane>
          <el-tab-pane :label="`待办(${todoData && todoData.length})`" name="second">
            <el-collapse
              v-if="todoData && todoData.length > 0"
              v-model="activeNames"
              style="height: 500px; overflow: auto"
              @change="handleChange"
            >
              <el-collapse-item v-for="(item, index) in todoData" :key="index" :name="index + 1">
                <template slot="title">
                  <span style="font-size: 18px; font-weight: bold">{{ item.notice_title }}</span>
                </template>
                <div style="display: flex; justify-content: space-between">
                  <span>{{ item.notice_content }}</span>
                  <div style="width: 20px"></div>
                  <el-button
                    style="margin-right: 15px"
                    type="text"
                    @click="isRead(item.notice_id, 'firstOrderPreparation')"
                    >已读
                  </el-button>
                </div>
                <!--                <div style="display: flex; justify-content: space-between; align-items: center">-->
                <!--                  <span>单号：{{ item.No }}</span>-->
                <!--                  <el-button style="margin-right: 15px" type="text">已读</el-button>-->
                <!--                </div>-->
                <!--                <div>-->
                <!--                  <span>轨迹：{{ item.status }}</span-->
                <!--                  >&nbsp;&nbsp;<span>{{ item.time }} </span>-->
                <!--                </div>-->
              </el-collapse-item>
            </el-collapse>
            <el-empty v-else description="暂无数据"></el-empty>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-popover>
  </div>
</template>

<script>
  import { getNoticeList, NoticeIsRead } from "@/api/login";

  export default {
    name: "NavCon",
    data() {
      return {
        activeName: "first",
        activeNames: [1, 2, 3, 4, 5],
        noticeData: null,
        todoData: null,
        total: null
      };
    },
    watch: {
      "$store.state.noticeNum": {
        deep: true, //深度监听设置为 true
        handler: function (newVal) {
          this.total = newVal.firstOrderInform + newVal.firstOrderPreparation;
        }
      }
    },
    methods: {
      handleClick(tab) {
        if (tab.name === "first") {
          this.getForData("firstOrderInform");
        } else if (tab.name === "second") {
          this.getForData("firstOrderPreparation");
        }
      },
      handleChange(val) {
        console.log(val);
      },
      getFristData() {
        this.getForData("firstOrderInform");
        this.getForData("firstOrderPreparation");
      },
      getForData(val) {
        getNoticeList({ notice_type: val }).then(res => {
          if (res.status === 200) {
            if (val === "firstOrderInform") {
              this.noticeData = JSON.parse(JSON.stringify(res.data));
            } else if (val === "firstOrderPreparation") {
              this.todoData = JSON.parse(JSON.stringify(res.data));
            }
          }
        });
      },
      isRead(val, type) {
        NoticeIsRead({ notice_id: val }).then(res => {
          if (res.status === 200) {
            this.getForData(type);
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .customPopper {
    min-width: 500px !important;
  }

  .m-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    .item-info-pop {
      display: flex;
      align-items: center;
    }

    .bell {
      color: black;
    }

    .item-child {
      display: flex;
      align-items: center;
      font-size: 13px;
    }
  }

  ::v-deep(.el-divider--horizontal) {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .transverseMenu {
    .bell {
      color: white;
    }
  }
</style>
