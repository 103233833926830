// 导入组件
import Vue from "vue";
import Router from "vue-router";
// 登录
import login from "@/views/login";
// 首页
import index from "@/views/index";
import VueRouter from "vue-router";

// 启用路由
Vue.use(Router);

// 解决报错
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// 针对 push 方法
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// 针对 replace 方法
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};
// 导出路由
let routes = [
  {
    path: "/",
    name: "",
    component: login,
    hidden: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: "/login",
    name: "登录",
    component: login,
    hidden: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: "*",
    name: "notFound",
    component: () => import("@/views/not-found/index"),
    meta: {
      requireAuth: false
    }
  },
  {
    path: "/index",
    name: "首页",
    component: index,
    iconCls: "el-icon-tickets",
    children: []
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
