import axios from "axios";
import router from "../router";
import store from "../vuex/store";

// ? "https://warehouse.e-kc.vip"
// : "https://warehouse.e-kc.vip";
let baseURL = process.env.NODE_ENV == "development" ? "http://warehouse.e-kc.com" : "https://warehouse.e-kc.vip";

let baseURL1 = process.env.NODE_ENV == "development" ? "http://api.e-kc.com" : "https://api.e-kc.vip";

// 登录请求方法
const loginInstance = (method, url, params) => {
  return axios({
    method: method,
    url: `${baseURL}${url}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: params,
    traditional: true,
    transformRequest: [
      function (data) {
        let ret = "";
        for (let it in data) {
          ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret;
      }
    ]
  }).then(res => res.data);
};
// 通用公用方法
const request = (method, url, params, responseType = undefined) => {
  return axios({
    method: method,
    url: `${baseURL}${url}`,
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      Authorization: sessionStorage.getItem("logintoken")
    },
    data: params,
    responseType: responseType
    // traditional: true,
    // transformRequest: [
    //   function(data) {
    //     let ret = "";
    //     for (let it in data) {
    //       ret +=
    //         encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    //     }
    //     return ret;
    //   }
    // ]
  }).then(res => res.data);
};
// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      let res = await axios({
        url: `${baseURL}/login/refresLogin`,
        method: "post",
        headers: {
          Authorization: window.sessionStorage.getItem("Refresh_Token")
        }
      });
      if (res.data.status === 200) {
        sessionStorage.setItem("logintoken", res.data.data.Access_Token);
        sessionStorage.setItem("Refresh_Token", res.data.data.Refresh_Token);
      } else {
        store.commit("logout", "false");
        router.push({ path: "/login" });
        // window.location.reload()
      }
    }
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
const request1 = (method, url, params) => {
  return axios({
    method: method,
    url: `${baseURL}${url}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: sessionStorage.getItem("logintoken")
    },
    data: params
    // traditional: true,
    // transformRequest: [
    //   function(data) {
    //     let ret = "";
    //     for (let it in data) {
    //       ret +=
    //         encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    //     }
    //     return ret;
    //   }
    // ]
  }).then(res => res.data);
};
//api请求
const requestApi = (method, url, params, responseType = undefined) => {
  return axios({
    method: method,
    url: `${baseURL1}${url}`,
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      Authorization: sessionStorage.getItem("logintoken")
    },
    data: params,
    responseType: responseType

    // traditional: true,
    // transformRequest: [
    //   function(data) {
    //     let ret = "";
    //     for (let it in data) {
    //       ret +=
    //         encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    //     }
    //     return ret;
    //   }
    // ]
  }).then(res => res.data);
};
const requestApi2 = (method, url, params) => {
  return axios({
    method: method,
    url: `${url}`,
    params
  }).then(res => res.data);
};

export { loginInstance, request, requestApi, request1, requestApi2 };
