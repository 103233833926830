import { loginInstance, request, requestApi } from "./request";

export const login = params => {
  return loginInstance("post", "/login/index", params);
};

export const getVerify = params => {
  return requestApi("post", "/index/verify", params);
};

export const logout = params => {
  return request("post", "/login/logout", params);
};

export const changePassword = params => {
  return request("post", "/login/changePassword", params);
};

export const bindMembeId = params => {
  return request("post", "/Notice/bindMembeId", params);
};
export const unbindMembeId = params => {
  return request("post", "/Notice/unbindMembeId", params);
};
//通知列表
export const getNoticeList = params => {
  return request("post", "/Notice/NoticeList", params);
};
//已读通知
export const NoticeIsRead = params => {
  return request("post", "/Notice/NoticeIsRead", params);
};
