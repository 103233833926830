export default {
  data() {
    return {};
  },
  watch: {},
  beforeMount() {},
  created() {},
  mounted() {},
  methods: {
    showNotify(data) {
      if (data.status === 411) {
        this.$notify({
          title: data.notice_title,
          message: data.notice_content,
          type: "error"
        });
      }
    },
    //news数据处理
    showMessage() {
      this.$message({
        message: "这是一条news的提示消息",
        type: "success"
      });
    },
    // system数据处理
    showSystem(data) {
      this.$notify({
        title: data.notice_title,
        message: data.notice_content,
        dangerouslyUseHTMLString: true,
        duration: 0,
        type: "success"
      });
    },
    submitData(data) {
      this.$store.commit("setNum", data.Num);
      this.$store.commit("setTotal", data.total);
    },
    resultData(data) {
      this.$store.commit("setNoticeContent", data.notice_content);
      this.$store.commit("setStatus", data.status);
    },
    setNoticeData(data) {
      this.$store.commit("setNoticeData", data.data);
    }
  },
  //注销window.onresize事件
  destroyed() {
    window.onresize = null;
  }
};
