<template>
  <el-container class="index-con">
    <el-header class="index-header" style="height: 60px">
      <navcon ref="nav"></navcon>
    </el-header>
    <!--    :width="showclass?'220px':'50px'"-->
    <el-container>
      <el-aside width="auto" v-if="$route.path !== '/home/workbench' && $store.state.active !== '/index'">
        <leftnav :allmenus="allmenu" @toggle="toggle"></leftnav>
      </el-aside>

      <el-main class="index-main" style="overflow: hidden; padding-bottom: 15px">
        <!-- 标签导航栏组件 -->
        <tagsview v-show="$route.path !== '/home/workbench'"></tagsview>
        <!--        height:(clienHeight-135)+'px'-->
        <div class="view" :style="{ overflow: auto, overflowX: 'hidden' }">
          <keep-alive>
            <router-view v-if="$route.path === '/list/waybillManage'"></router-view>
          </keep-alive>
          <router-view v-if="$route.path !== '/list/waybillManage'"></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
  //引入vuex中的state，不了解的参考vuex文档
  import { mapState } from "vuex";
  // 导入组件
  import navcon from "../components/navcon.vue";
  import leftnav from "../components/leftnav.vue";
  import tagsview from "../components/tagsview.vue";
  import { auto } from "html-webpack-plugin/lib/chunksorter";
  export default {
    name: "index",
    data() {
      return {
        showclass: true,
        showtype: false,
        allmenu: [],
        menus: [],
        clienHeight: document.documentElement.clientHeight || document.body.clientHeight,
        timer: null
      };
    },
    // 注册组件
    components: {
      navcon,
      leftnav,
      tagsview
    },
    computed: {
      //引入vuex中state的变量，可以直接this.xxx调用到
      ...mapState(["isCollapse"])
    },
    methods: {
      auto() {
        return auto;
      },
      toggle(value) {
        if (value) {
          this.showclass = true;
        } else {
          setTimeout(() => {
            this.showclass = false;
          }, 300);
        }
      }
    },
    watch: {
      "$store.state.active": {
        handler() {
          if (this.menus.length > 0) {
            this.menus.forEach(item => {
              if (item.redirect === this.$store.state.active || item.path === this.$store.state.active) {
                this.allmenu = item.children;
              }
            });
          }
        }
      },
      "$route.path": {
        handler(val) {
          let str1 = val.indexOf("/");
          let str2 = val.indexOf("/", str1 + 1);
          let result = val.slice(0, str2);
          if (this.menus.length > 0) {
            this.menus.forEach(item => {
              if (item.redirect === result || item.path === result) {
                this.allmenu = item.children;
              }
            });
          }
          if (result !== "/home") {
            this.$refs.nav.activeIndex = result;
            this.$store.commit("setActive", result);
          } else {
            this.$refs.nav.activeIndex = "/home/workbench";
            this.$store.commit("setActive", "/home/workbench");
          }
        }
      },
      clienHeight(val) {
        //监控浏览器高度变化
        if (!this.timer) {
          this.clienHeight = val;
          this.$store.commit("listenHeight", val);
          this.timer = true;
          let that = this;
          setTimeout(function () {
            that.timer = false;
          }, 400);
        }
      }
    },
    created() {
      if (sessionStorage.getItem("logintoken")) {
        const obj = JSON.parse(sessionStorage.getItem("userdata"));
        this.menus = obj.menu;
        this.menus.forEach(item => {
          if (item.children.length > 0) {
            item["redirect"] = item.children[0].path;
          }
          if (item.redirect === this.$store.state.active || item.path === this.$store.state.active) {
            this.allmenu = item.children;
          }
        });
      }
    },
    mounted() {
      const that = this;
      window.onresize = () => {
        return (() => {
          (window.clienHeight = document.documentElement.clientHeight || document.body.clientHeight),
            (that.clienHeight = window.clienHeight);
        })();
      };
    },
    beforeUpdate() {},
    // 挂载前状态(里面是操作)
    beforeMount() {}
  };
</script>
<style scoped lang="scss">
  .el-menu-item-group__title {
    padding: 0 !important;
  }

  .index-con {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .index-header,
  .index-main {
    padding: 0;
  }
  .el-main {
    //background-color: #f5f5f5;
    background-color: #eef2f6;
    padding: 0 15px;
    //position: absolute;
    //left: 220px;
    //right: 0;
    //top: 60px;
    //bottom: 0;
    //overflow-y: scroll;
    height: calc(100vh - 70px);
  }
  .el-aside {
    overflow: hidden;
    //display: block;
    //position: absolute;
    //left: 0;
    //top: 60px;
    //bottom: 0;
    height: calc(100vh - 70px);
  }
  .el-header {
    position: relative;
    width: 100%;
    height: 60px;
  }
  .view {
    overflow: auto;
  }
  .el-footer {
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    text-align: center;
    background-color: #ffffff;
    flex-shrink: 0;
    color: rgba(0, 0, 0, 0.45);
  }
</style>
