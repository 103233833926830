// zn.js
import zhLocale from "element-ui/lib/locale/lang/zh-CN"; //引入element语言包
export default {
  main: {
    message: "消息",
    display: "展示",
    shelfNumber: "货架号",
    shelfStatus: "货架状态",
    operate: "操作",
    addShelves: "新增货架",
    area: "库区"
  },
  btn: {
    delete: "删除",
    add: "新增",
    edit: "修改",
    export: "导出",
    Print: "打印",
    import: "导入",
    batchDeletion: "批量删除",
    batchExport: "批量导出",
    batchPrinting: "批量打印",
    ExcelImport: "Excel导入",
    NewWarehouseArea: "新增库区"
  },
  placeholder: {
    PleaseSelectTheWarehouse: "请选择所属库区",
    PleaseEnterTheNewShelfNumber: "请输入新增货架号",
    EnterTheWarehouseName: "输入库区名称",
    EnterWarehouseDetails: "输入库区详情"
  },
  ...zhLocale
};
