<template>
  <el-card shadow="never" class="card" style="overflow: auto">
    <el-row :gutter="5">
      <el-col :span="24" style="text-align: center">
        <el-button
          v-if="isHidden === 'notShipped'"
          type="primary"
          size="small"
          icon="el-icon-plus"
          style="margin-bottom: 10px; width: 100%"
          @click="addBox"
          round
        >
          {{ title }}
        </el-button>
        <!--        <div v-if="isTicket">-->
        <!--          <el-radio-group v-model="radio" @change="selectTypeVal">-->
        <!--            <el-radio label="invoice">运单</el-radio>-->
        <!--            <el-radio label="pack">批次</el-radio>-->
        <!--          </el-radio-group>-->
        <!--        </div>-->
      </el-col>
    </el-row>
    <el-row :gutter="10" v-if="isTicket">
      <el-col :span="16">
        <el-input placeholder="输入运单号" size="small" style="margin: 20px 0" v-model="filterText"></el-input>
      </el-col>
      <el-col :span="8">
        <el-button style="margin: 20px 0" size="small" type="primary" icon="el-icon-search" @click="searchOrder"
          >搜索</el-button
        >
      </el-col>
    </el-row>
    <el-row :gutter="10" v-else>
      <el-col :span="24">
        <el-input placeholder="输入关键字查找" size="small" style="margin: 20px 0" v-model="filterText"></el-input>
      </el-col>
    </el-row>
    <el-tree
      :props="defaultProps"
      :data="treelist"
      :default-expand-all="expand"
      :filter-node-method="filterNode"
      @node-click="handleNodeClick"
      ref="tree"
    >
      <span class="custom-tree-node" slot-scope="{ data }">
        <span v-if="isTicket">单号：</span>
        <span v-if="isTicket">{{ data.key }}</span>
        <span>
          <span style="margin-right: 10px"
            ><slot
              v-bind:user="{
                pack_id: data.pack_id,
                channel_receipt_id: data.channel_receipt_id
              }"
            ></slot
          ></span>
          <span>{{ data.pack_No }}</span></span
        >
        <span>
          <span style="margin-right: 10px"><slot name="sumbit"></slot></span>
          <el-button
            style="font-size: 18px; color: red"
            v-if="!isTicket && isHidden === 'notShipped'"
            type="text"
            size="mini"
            icon="el-icon-delete"
            @click.stop="() => remove(data)"
          >
          </el-button>
        </span>
      </span>
    </el-tree>
  </el-card>
</template>

<script>
  export default {
    name: "LeftTreeBox",
    props: {
      treelist: {
        type: Array
      },
      fieldname: {
        type: Boolean
      },
      title: {
        type: String,
        default: ""
      },
      isHidden: {
        type: String
      },
      isTicket: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        filterText: "",
        expand: true,
        categoryForm: {},
        defaultProps: {
          children: "children",
          label: "key"
        },
        radio: "invoice"
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    methods: {
      //搜索
      searchOrder() {
        this.$emit("search", this.filterText);
      },
      //搜索请求
      filterNode(value, data) {
        if (this.isTicket) {
          return data.key.indexOf(value) !== -1;
        } else {
          return data.pack_No.indexOf(value) !== -1;
        }
      },
      handleNodeClick(data) {
        if (this.isTicket) {
          this.$emit("refesh_list", data.val);
          this.$emit("update:search_data", data.key);
        } else {
          this.$emit("update:search_data", data.pack_No);
          this.$emit("refesh_list", data);
        }
      },
      toggleRowExpansion() {
        this.expand = !this.expand;
        this.changeTreeNodeStatus(this.$refs.tree.store.root);
      },
      //-----------折叠-----------------------------------
      changeTreeNodeStatus(node) {
        node.expanded = this.expand;
        for (let i = 0; i < node.childNodes.length; i++) {
          node.childNodes[i].expanded = this.expand;
          if (node.childNodes[i].childNodes.length > 0) {
            this.changeTreeNodeStatus(node.childNodes[i]);
          }
        }
      },
      selectTypeVal(val) {
        this.$emit("changeType", val);
      },
      //---------------货架------------------------------
      remove(data) {
        this.$confirm("确定操作吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$emit("removeCategory", data.pack_id);
          })
          .catch();
      },
      //---------------新增------------------------------
      addBox() {
        this.$emit("addBox");
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
  }

  .card {
    min-height: 800px;
  }

  .custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span {
      margin: 10px 0;
      font-size: 14px;
    }
  }

  .el-tree-node__expand-icon.is-leaf {
    display: none;
  }

  ::v-deep .el-tree-node__expand-icon.is-leaf {
    display: none;
  }
  ::v-deep .el-tree-node {
    margin: 20px 0;
  }
</style>
