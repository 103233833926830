<template>
  <el-cascader
    style="display: block"
    size="small"
    :options="options"
    v-model="selection"
    placeholder="请选择所在区域"
    @change="changeVal"
    filterable
    :props="checkstrictly"
  >
  </el-cascader>
</template>

<script>
  import { regionData, codeToText, TextToCode } from "element-china-area-data";

  export default {
    name: "ChinaArea",
    props: {
      optionData: {
        type: Array,
        default: () => []
      },
      checkstrictly: {
        type: Object
      }
    },
    data() {
      return {
        options: regionData,
        selection: this.optionData
      };
    },
    watch: {
      optionData: {
        handler(val) {
          if (val.length > 0) {
            let areaData = JSON.parse(JSON.stringify(val));
            console.log(areaData);
            let val1 = TextToCode[areaData[0]].code;
            let val2 = TextToCode[areaData[0]][areaData[1]].code;
            let val3 = TextToCode[areaData[0]][areaData[1]][areaData[2]].code;
            this.selection = [val1, val2, val3];
          }
        },
        immediate: true
      }
    },
    methods: {
      changeVal(val) {
        let arr = [];
        arr.push(codeToText[val[0]]);
        arr.push(codeToText[val[1]]);
        arr.push(codeToText[val[2]]);
        this.$emit("submitArea", arr);
      }
    }
  };
</script>

<style scoped></style>
