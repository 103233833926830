import { request, requestApi, requestApi2 } from "./request";

//获取运输渠道列表
export const getChannelDeliver = () => {
  return requestApi("get", "/index/channelDeliver");
};
//获取产品渠道列表
export const getChannelReceipt = () => {
  return requestApi("get", "/index/channelReceipt");
};
//轨迹状态
export const getWaybillTrack = data => {
  return requestApi("post", "/index/waybillTrack", data);
};
//业务类型
export const getBusiness = () => {
  return requestApi("get", "/index/busn");
};
//运输列表
export const getTransport = () => {
  return requestApi("get", "/index/transport");
};
//仓库列表
export const getWarehouseList = () => {
  return requestApi("get", "/index/warehouseList");
};
//仓库数据
export const whGetData = () => {
  return request("get", "/index/getData");
};
//打印
export const printOrder = data => {
  return requestApi("post", "/index/printOrder", data);
};
//更换结果
export const changeWh = data => {
  return request("post", "/index/changeWh", data);
};
//全局产品渠道下拉
export const getGlobalChannelReceipt = data => {
  return requestApi("post", "/index/channelReceipt", data);
};
export const orderDoPrint = data => {
  return requestApi2("get", "http://127.0.0.1:9999/doPrinter", data);
};
//上传url
export const uploadImgUrl = data => {
  return requestApi("post", "/index/upload", data);
};
