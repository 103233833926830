/** * 左边菜单 */
<template>
  <el-menu
    :collapse="collapsed"
    :default-active="$route.path"
    active-text-color="#fff"
    background-color="#fff"
    class="el-menu-vertical-demo"
    collapse-transition
    router
  >
    <div class="titleMenu" style="font-weight: bold">
      <span v-if="$store.state.active === '/user'" :style="collapsed ? 'display: none' : ''"
        ><i class="el-icon-user" style="font-size: 20px; margin-right: 7px"></i>用户区</span
      >
      <span v-if="$store.state.active === '/system'" :style="collapsed ? 'display: none' : ''"
        ><i class="el-icon-setting" style="font-size: 20px; margin-right: 7px"></i>系统设置</span
      >
      <span v-if="$store.state.active === '/list'" :style="collapsed ? 'display: none' : ''"
        ><i class="el-icon-s-data" style="font-size: 20px; margin-right: 7px"></i>列表区</span
      >
      <span v-else-if="$store.state.active === '/worker'" :style="collapsed ? 'display: none' : ''"
        ><i class="el-icon-s-platform" style="font-size: 20px; margin-right: 7px"></i>工作区</span
      >
      <span v-else-if="$store.state.active === '/firstwaybill'" :style="collapsed ? 'display: none' : ''"
        ><i class="el-icon-s-platform" style="font-size: 20px; margin-right: 7px"></i>头程区</span
      >
      <span v-else-if="$store.state.active === '/storage'" :style="collapsed ? 'display: none' : ''"
        ><i class="el-icon-school" style="font-size: 20px; margin-right: 7px"></i>仓储配置</span
      >
      <span v-else-if="$store.state.active === '/cainiao'" :style="collapsed ? 'display: none' : ''"
        ><i class="iconfont icon-daohang-shujufenxi" style="font-size: 18px; margin-right: 7px"></i>菜鸟运单</span
      >
      <span v-else-if="$store.state.active === '/financial'" :style="collapsed ? 'display: none' : ''"
        ><i class="iconfont icon-caiwuguanli" style="font-size: 20px; margin-right: 7px"></i>财务区</span
      >
      <span v-else-if="$store.state.active === '/ProductConfig'" :style="collapsed ? 'display: none' : ''"
        ><i class="el-icon-menu" style="font-size: 20px; margin-right: 7px"></i>货品配置</span
      >
    </div>
    <div v-for="menu in allmenus" :key="menu.node_id" style="font-weight: bold">
      <el-submenu v-if="menu.children.length > 0" :index="menu.path">
        <template slot="title">
          <i :class="menu.icon"></i>
          <span style="font-weight: bold">{{ menu.title }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="chmenu in menu.children"
            :key="chmenu.node_id"
            :index="chmenu.path"
            @click="clickMenu(chmenu)"
          >
            <i :class="chmenu.icon"></i>
            <span>{{ chmenu.title }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item v-else :index="menu.path" style="font-weight: bold" @click="clickMenu(menu)">
        <i :class="menu.icon"></i>
        <span>{{ menu.title }}</span>
      </el-menu-item>
    </div>
  </el-menu>
</template>
<script>
  export default {
    name: "leftnav",
    props: {
      allmenus: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        isCollapse: true,
        collapsed: false,
        imgshow: require("@/assets/img/show1.png"),
        imgsq: require("@/assets/img/sq1.png"),
        isFullscreen: false
      };
    },
    methods: {
      toggle() {
        this.collapsed = !this.collapsed;
        this.$emit("toggle", this.collapsed);
      },
      //点击把菜单的名字传出去
      clickMenu(val) {
        this.$store.commit("pushtags", val);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .buttonimg {
    height: 60px;
    background-color: transparent;
    border: none;
  }

  .showimg {
    width: 22px;
    height: 22px;
  }

  .showimg:active {
    border: none;
  }

  //.el-menu-item.is-active {
  //  background-color: #409eff !important;
  //}

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 180px;
    min-height: 400px;
    margin: 0 20px;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    border: none;
    text-align: left;
  }

  .el-menu--collapse {
    width: 43px;
  }

  .el-menu-item-group__title {
    padding: 0;
  }

  .el-menu {
    border: none;
  }

  .logobox {
    height: 40px;
    line-height: 40px;
    color: #9d9d9d;
    font-size: 20px;
    text-align: left;
    padding: 15px;
  }

  .logoimg {
    height: 35px;
  }

  .titleMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px;
    font-size: 16px;
    border-bottom: 1px solid #fafafa;
    padding-left: 20px;
    border-top: 2px solid #fafafa;
  }

  .el-menu-item.is-active {
    background-color: #2e82ff !important;
  }

  .el-menu-item {
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    margin: 20px auto;
  }

  ::v-deep .el-submenu__title {
    //margin-left: 10px;
  }

  ::v-deep .el-submenu .el-menu-item {
    min-width: 180px;
  }
</style>
