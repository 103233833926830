<template>
  <div>
    <el-button-group>
      <el-tooltip class="item" effect="dark" :content="expand ? '菜单收起' : '菜单展开'" placement="top">
        <el-button
          v-if="expand_status"
          :icon="expand ? 'el-icon-top' : 'el-icon-bottom'"
          size="small"
          @click="toggleRowExpansion"
        ></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="search_visible ? '搜索框收起' : '搜索框展开'" placement="top">
        <el-button class="hidden-sm-and-down" icon="el-icon-search" size="small" @click="searchshow"></el-button>
      </el-tooltip>
      <el-button class="hidden-sm-and-down" icon="el-icon-refresh" size="small" @click="refesh_list"></el-button>
    </el-button-group>
  </div>
</template>
<script>
  export default {
    name: "TableTool",
    props: {
      expand_status: {
        type: Boolean,
        default: false
      },
      expand: {
        type: Boolean,
        default: false
      },
      search_visible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      searchshow() {
        this.$emit("update:search_visible", !this.search_visible);
      },
      refesh_list() {
        this.$emit("refesh_list");
      },
      toggleRowExpansion() {
        this.$emit("toggle");
      }
    }
  };
</script>
<style lang="scss" scoped></style>
