/** * 分页组件 */
<template>
  <el-pagination
    :current-page="childMsg.currentPage"
    :page-size="childMsg.pageSize"
    :page-sizes="[20, 50, 100, 300, 500, 1000]"
    :total="childMsg.total"
    background
    class="page-box"
    layout="total, sizes, prev, pager, next, jumper"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  >
  </el-pagination>
</template>
<script>
  export default {
    name: "Pagination",
    props: ["childMsg"],
    data() {
      return {
        pageparm: {
          currentPage: this.childMsg.currentPage,
          pageSize: this.childMsg.pageSize
        }
      };
    },
    created() {},
    methods: {
      handleSizeChange(val) {
        /**
         * 子传父
         * 参数1 父元素方法
         * 参数2 数据
         */
        this.pageparm.pageSize = val;
        this.$emit("callFather", this.pageparm);
      },
      handleCurrentChange(val) {
        /**
         * 子传父
         * 参数1 父元素方法
         * 参数2 数据
         */
        this.pageparm.currentPage = val;
        this.$emit("callFather", this.pageparm);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .page-box {
    margin: 10px auto;
    text-align: center;
  }
</style>
