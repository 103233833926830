<template>
  <el-card shadow="never" class="card" style="overflow: auto">
    <el-row :gutter="5" v-if="fieldname">
      <el-col :span="24">
        <el-input
          :placeholder="$t('placeholder.EnterTheWarehouseName')"
          size="small"
          style="margin-bottom: 10px"
          v-model="categoryForm.category_title"
        ></el-input>
        <el-input
          :placeholder="$t('placeholder.EnterWarehouseDetails')"
          size="small"
          style="margin-bottom: 10px"
          v-model="categoryForm.category_content"
        ></el-input>
      </el-col>
      <el-col :span="24" style="text-align: center">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          style="margin-bottom: 10px; width: 100%"
          @click="addCategory"
          >{{ $t("btn.NewWarehouseArea") }}</el-button
        >
      </el-col>
    </el-row>
    <el-row :gutter="10" v-else>
      <el-col :span="22">
        <el-input
          placeholder="输入关键字进行过滤"
          size="small"
          style="margin-bottom: 10px"
          v-model="filterText"
        ></el-input>
      </el-col>
      <!--      <el-col :span="2">-->
      <!--        <el-tooltip-->
      <!--          class="item"-->
      <!--          effect="dark"-->
      <!--          :content="expand ? '收起' : '展开'"-->
      <!--          placement="top"-->
      <!--        >-->
      <!--          <i-->
      <!--            style="margin-top: 8px; font-size: 20px; cursor: pointer"-->
      <!--            @click="toggleRowExpansion"-->
      <!--            :class="expand ? 'el-icon-top' : 'el-icon-bottom'"-->
      <!--          ></i>-->
      <!--        </el-tooltip>-->
      <!--      </el-col>-->
    </el-row>
    <el-tree
      :props="defaultProps"
      :data="treelist"
      :default-expand-all="expand"
      :filter-node-method="filterNode"
      @node-click="handleNodeClick"
      ref="tree"
    >
      <span class="custom-tree-node" slot-scope="{ data }">
        <span v-if="fieldname">{{ data.category_title }}</span>
        <!--        <span v-if="fieldname">{{ data.category_content }}</span>-->
        <div v-if="!fieldname" style="margin-left: 25px">{{ data.key }}</div>
        <el-button v-if="fieldname" type="text" size="mini" icon="el-icon-delete" @click.stop="() => remove(data)">
        </el-button>
      </span>
    </el-tree>
  </el-card>
</template>

<script>
  export default {
    name: "LeftTree",
    props: {
      treelist: {
        type: Array
      },
      fieldname: {
        type: Boolean
      }
    },
    data() {
      return {
        filterText: "",
        expand: true,
        categoryForm: {},
        defaultProps: {
          children: "children",
          label: "key"
        }
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    methods: {
      filterNode(value, data) {
        return data.key.indexOf(value) !== -1;
      },
      handleNodeClick(data) {
        if (this.fieldname) {
          this.$emit("update:search_data", data.category_id);
          this.$emit("refesh_list", data.category_id);
        } else {
          this.$emit("update:search_data", data.val);
          this.$emit("refesh_list", data.val);
        }
      },
      toggleRowExpansion() {
        this.expand = !this.expand;
        this.changeTreeNodeStatus(this.$refs.tree.store.root);
      },
      //-----------折叠-----------------------------------
      changeTreeNodeStatus(node) {
        node.expanded = this.expand;
        for (let i = 0; i < node.childNodes.length; i++) {
          node.childNodes[i].expanded = this.expand;
          if (node.childNodes[i].childNodes.length > 0) {
            this.changeTreeNodeStatus(node.childNodes[i]);
          }
        }
      },
      //---------------货架------------------------------
      remove(data) {
        this.$confirm("确定操作吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$emit("removeCategory", data.category_id);
          })
          .catch();
      },
      //---------------新增------------------------------
      addCategory() {
        if (this.categoryForm.category_title) {
          this.$emit("addCategory", this.categoryForm);
        } else {
          this.$message({
            type: "warning",
            message: "请输入区域名称"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    min-height: 800px;
  }
  .custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 16px;
    //border-bottom: 1px solid #eeebeb;
    span {
      margin: 10px 0;
    }
  }
  ::v-deep .el-tree-node__expand-icon.is-leaf {
    display: none;
  }
</style>
