// 封装操作 sessionStorage 的方法

const TOKEN_KEY = "logintoken";
const CLIENTID = "cId";

/**
 * 保存token
 */
export const setToken = function (obj) {
  sessionStorage.setItem(TOKEN_KEY, JSON.stringify(obj));
};

/**
 * 获取token
 */
export const getToken = function () {
  // 取出来的时候是一个json字符串，所以还要转回JS对象
  return sessionStorage.getItem(TOKEN_KEY);
};

/**
 * 删除token
 */
export const removeToken = function () {
  sessionStorage.removeItem(TOKEN_KEY);
};
/**
 * 保存client_id
 */
export const setClientId = function (obj) {
  sessionStorage.setItem(CLIENTID, JSON.stringify(obj));
};
/**
 * 获取client_id
 */
export const getClientId = function () {
  // 取出来的时候是一个json字符串，所以还要转回JS对象
  return JSON.parse(sessionStorage.getItem(CLIENTID));
};
/**
 * 删除client_id
 */
export const removeClientId = function () {
  sessionStorage.removeItem(CLIENTID);
};
