<template>
  <div>
    <el-form-item>
      <el-button type="primary" size="small" icon="el-icon-search" @click="refesh_list">查询 </el-button>
      <el-button size="small" icon="el-icon-refresh" @click="reset">重置 </el-button>
    </el-form-item>
  </div>
</template>
<script>
  export default {
    name: "SearchTool",
    props: {
      expand_status: {
        type: Boolean,
        default: false
      },
      expand: {
        type: Boolean,
        default: false
      },
      search_visible: {
        type: Boolean,
        default: false
      },
      is_clear: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {};
    },
    methods: {
      refesh_list() {
        this.$emit("refesh_list");
      },
      reset() {
        this.$emit("update:search_data", {});
        this.$emit("update:is_clear", true);
        this.$emit("refesh_list");
      }
    },
    created() {},
    mounted() {}
  };
</script>
<style lang="scss" scoped>
  ::v-deep .el-form-item {
    text-align: center;

    .el-form-item__content {
      margin-left: -0.7rem;
    }
  }
</style>
