export default {
  install(Vue) {
    const req = require.context("./", false, /\.vue$/);
    req.keys().forEach(item => {
      const cmps = req(item).default;
      //全局注册
      Vue.component(cmps.name, cmps);
    });
  }
};
